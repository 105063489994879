var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Header", {
        staticClass: "fixed-top",
        attrs: { title: "保障范围", back: true },
      }),
      _vm.name == "万年青-传承"
        ? _c("div", { staticClass: "box-items" }, [
            _c("div", { staticClass: "insu-title" }, [_vm._v("投保说明")]),
            _c("div", [
              _vm._v(
                "1.上列为您的基本计划主要利益之说明摘要，如欲知悉更多资料，或（如适当）索取更详尽的建议书，请与您的持牌保险中介人或本公司联络。"
              ),
            ]),
            _c("div", [
              _vm._v(
                "2.以上只显示您的基本计划的利益说明，并未将「保障摘要」所列出之附加保障（如适用）计算在内。"
              ),
            ]),
            _c("div", [
              _vm._v(
                "3.以上数据是基于下列假设而定：（1）现行的归原红利及终期红利分配比例一直维持不变；（2）保单没有任何保单贷款/预支金额；（3）保单并无提取任何款项；（4）您会全数支付应缴保费。"
              ),
            ]),
            _c("div", [
              _vm._v(
                "4.以上非保证金额已考虑保单下可支付的归原红利及终期红利（统称为「红利」）。归原红利在公布后将于本保单内累积，而在日后公布终期红利时，终期红利或会有所增减。已公布的红利之面值为其中一项厘定身故保障之因素。惟当保单退保（全部或部份）或保单终止（非因受保人身故而引致）时，本公司仅支付红利的现金价值。红利乃根据本公司现时预计之红利分配推算，而并非保证的，并可由本公司全权厘定。实际获发之金额或比上述所显示者较高或较低。而本公司的红利分配推算基本上根据数个经验因素之表现而改变，其中投资回报（包含资产拖欠及投资开支所带来的影响）通常被视为该等红利表现的主要决定因素。其他因素包括（但不限于）索偿经验、保单开支、税项及保单持有人的终止保单经验。"
              ),
            ]),
            _c("div", [
              _vm._v(
                "5.以上显示的退保价值总额是保证现金价值、非保证累积归原红利之现金价值（如有）及终期红利之现金价值（如有）之总和，加上存放于本公司之任何款项，并扣除任何贷款与利息的金额。"
              ),
            ]),
            _c("div", [
              _vm._v(
                "6.以上显示的身故保障总额相等于（i）及（ii）之较高者，（i）基本计划之到期及已缴保费总额减任何已提取之累积归原红利现金价值；及（ii）就受保人身故当日的保证现金价值及任何累积归原红利和终期红利的面值之总和；加上存放于本公司之任何款项，并扣除任何贷款与利息的金额。"
              ),
            ]),
            _c("div", [
              _vm._v(
                "7.您可提取全部或部份累积归原红利之现金价值（如有），随后的累积归原红利之现金价值及面值将相应减少。提取归原红利将减低保单的长期价值。"
              ),
            ]),
            _c("div", [
              _vm._v(
                "8.于审视上述说明的金额时，应注意未来生活成本很可能会因通胀而上升。"
              ),
            ]),
            _c("div", [
              _vm._v(
                "9.请注意保费征费并不构成任何保费的一部分，并且不包含在相关的说明所示的计算中。"
              ),
            ]),
          ])
        : _vm._e(),
      _vm.name == "富通守护168加强版"
        ? _c("div", { staticClass: "box-items" }, [
            _c("div", [
              _vm._v(
                "「守护168」危疾保障计划（加强版）提供全面的人寿及危疾保障（包括于投保时或投保前未发现的先天性受保障之危疾及儿童疾病），兼可助您增值潜在财富，面对突如其来的状况都能处之泰然。"
              ),
            ]),
            _vm._m(0),
          ])
        : _vm._e(),
      _vm.name == "爱伴航"
        ? _c("div", { staticClass: "box-items" }, [
            _c("div", [
              _vm._v(
                "本摘要内容只供您作参考之用。请阅读本计划建议书之其他部份，以更全面了解您的保单利益。"
              ),
            ]),
            _c("div", [
              _vm._v(
                "「爱伴航」是一份分红保险计划，提供终身人寿及危疾保障，以及累积财富的机会，全面涵盖58种危疾（包括57种严重疾病及1种非严重疾病）、44种早期危疾及因危疾范畴以外的身体状况（包括传染病及受伤）导致需要入住深切治疗病房（ICU），全面关顾您的健康需要，并为癌症、心脏病、中风及脑退化疾病（如亚尔兹默氏病）提供多重赔偿，灵活守护您的健康。"
              ),
            ]),
            _c("h3", [_vm._v("1.身故赔偿：")]),
            _c("div", [
              _vm._v(
                "若受保人（即保单内受保障的人士）不幸身故，我们将支付身故赔偿予您指定的保单受益人。身故赔偿将包括："
              ),
            ]),
            _c("div", [
              _vm._v(
                "i.现时保额；及一笔过支付的非保证现金（如有），即「终期分红」，须在保单已生效5年或以后，方可获发此笔终期分红。"
              ),
            ]),
            _c("div", [
              _vm._v(
                "ii.现时保额是指我们从原有保额中扣除任何预支的保额。原有保额是指您所投保的保障金额。在派发赔偿前，我们会先扣除所有未偿还之保单欠款。"
              ),
            ]),
            _c("h3", [_vm._v(" 2.保障疾病之赔偿：")]),
            _c("div", [
              _vm._v(
                "若受保人确诊患上受保的58种危疾（包括57种严重疾病及1种非严重疾病）、44种早期危疾及／或符合深切治疗保障赔偿的条件，我们将向您赔偿："
              ),
            ]),
            _c("div", [_vm._v("i.根据保障疾病赔偿一览表对应的赔偿額；及")]),
            _c("div", [
              _vm._v(
                " ii.相应的非保证终期分红（如有），须在保单已生效5年或以后，方可获发此笔终期分红。于保障下，在保单有效期内，每种受保疾病（原位癌除外）及每个级别的深切治疗保障赔偿可获1次预支赔偿，而原位癌在不同器官最多可获2次预支赔偿。"
              ),
            ]),
            _c("div", [
              _vm._v(
                "除特选危疾七重赔偿、持续癌症现金选项及脑退化疾病终身年金赔偿外，保单下已作出的赔偿总额合共不可超过原有保额的100%（不包括任何终期分红）。此外，基本保单的现时保额将会因扣除已支付的预支赔偿而减少，而未来保费、保证现金价值及任何终期分红亦会根据现时保额相应减少。当预支赔偿合共超过原有保额之100%，保证现金价值将随现时保额递减至零，终期分红将不会再公布。在派发赔偿前，我们会先扣除所有未偿还之保单欠款。"
              ),
            ]),
            _c("h3", [_vm._v(" 3.首10年升级保障：")]),
            _c("div", [
              _vm._v(
                " 在首10个保单年度，我们会提供升级保障，即适用于58种危疾（包括57种严重疾病及1种非严重疾病）、身故保障或级别二深切治疗保障赔偿的一笔过额外35%原有保额的赔偿。此升级保障不会被任何早期危疾及级别一深切治疗保障赔偿的预支赔偿减少。"
              ),
            ]),
            _c("div", [
              _vm._v(
                " 根据保单审批时的核保决定，我们可为您提供升级保障的转换权。您可选择在升级保障生效期的最后1个保单年度或紧随着受保人64岁生日后之保障周年日起（以较先者为准），把升级保障的结余转换为终身寿险或危疾保障终身寿险计划（须额外缴付保费），而毋须再次提供健康申报。一经转换后，升级保障将被退保，而新保单将于退保日起生效。"
              ),
            ]),
            _c("h3", [_vm._v(" 4.深切治疗保障赔偿：")]),
            _c("div", [
              _vm._v(
                " 「爱伴航」为任何危疾范畴以外的身体状况（包括传染病及受伤）导致需要入住深切治疗病房（ICU）提供2个级别的深切治疗保障赔偿。"
              ),
            ]),
            _c("div", [
              _vm._v(
                " 于级别一深切治疗保障赔偿下，如果受保人已入住深切治疗病房并接受侵入性维生支持连续72小时或以上，您可获得20%原有保额的预支赔偿。如于香港及澳门以外地区入住深切治疗病房，级别一的赔偿額将调低至10%原有保额。"
              ),
            ]),
            _c("div", [
              _vm._v(
                "如索偿同时符合级别一深切治疗保障赔偿及，早期危疾赔偿或严重儿童疾病赔偿，我们将只就早期危疾赔偿或严重儿童疾病赔偿支付赔偿。"
              ),
            ]),
            _c("div", [
              _vm._v(
                "于级别二深切治疗保障赔偿下，如果受保人已入住深切治疗病房并接受侵入性维生支持连续120小时或以上，并进行了自愿医保（VHIS）手术表中所定义的复杂手术，您可获得100%原有保额，须扣减任何已支付的预支赔偿。如受保人接受的手术不属于自愿医保手术表中的任何一项手术类别或如手术表不再有效或被取代，或此手术表之手术分类重新命名或有其他更改，我们将会参考香港政府、相关机构或医学组织发布的手术表及／或任何其他刊物或数据中以难度和复杂程度相约的其他手术作出合理决定适用的手术类别。"
              ),
            ]),
            _c("div", [
              _vm._v(
                "如索偿同时符合级别二深切治疗保障赔偿及危疾赔偿，我们将只就危疾赔偿支付赔偿，除了就须作手术之脑动脉瘤索偿的情况，我们将支付级别二深切治疗保障赔偿。"
              ),
            ]),
            _c("h3", [_vm._v("5.特选危疾七重赔偿：")]),
            _c("div", [
              _vm._v(
                " 当您的索偿已达至原有保额的100%，您将获豁免缴付将来的保费。其后，特选危疾七重赔偿会为癌症（包括癌症持续、扩散、复发或新确诊癌症）、心脏病、中风及或脑退化疾病（如亚尔兹默氏病）额外提供最多7次赔偿，每次赔偿为原有保额的100%。"
              ),
            ]),
            _c("div", [
              _vm._v(
                "包括严重疾病赔偿在内，赔偿总次数不得超过相关疾病的限额：癌症：5次赔偿心脏病或中风：合共2次赔偿亚尔兹默氏病／不可还原之器质性脑退化疾病：1次赔偿就此保障所提出的所有索偿而言，受保人均须于确诊日起计生存超过15日，亦必须符合1年等候期的要求 ，如两次索偿均为癌症，等候期则为3年。如上一次索偿为早期危疾、非严重疾病或级别一深切治疗保障赔偿，则不设等候期。一旦索偿心脏病或中风后，其后每次就心脏病提出索偿，须为新确诊的另一次心脏事故，并须提供全新诊断证明，以确定符合此保单下有关心脏病之定义。其后每次就中风提出索偿，须为新确诊的另一次脑血管意外或事故及因此产生新或更严重的神经功能性障碍，并须提供全新诊断证明，以确定符合此保单下有关中风之定义。受保人须提供在30分为满分的简短智能测试（MMSE）中取得10分或以下的证明，方符合特选危疾七重赔偿下支付亚尔兹默氏病／不可还原之器质性脑退化疾病的索偿。有关保障疾病之定义，请参阅保单契约。"
              ),
            ]),
            _c("div", [
              _vm._v(
                "特选危疾七重赔偿的保障至受保人85岁。若受保人曾就前列腺癌提出索偿，并于年届70岁后持续患有相同癌症因而再次索偿，受保人必须于上一次及再次确诊前列腺癌期间，已接受或正接受整个医疗所需及针对该癌症的手术、电疗、化疗、标靶治疗或以上之治疗组合（不包括激素治疗），方会支付有关赔偿。"
              ),
            ]),
            _c("h3", [_vm._v("6.持续癌症现金选项：")]),
            _c("div", [
              _vm._v(
                "当严重疾病赔偿或特选危疾七重赔偿已就癌症作赔偿，您可以选择行使持续癌症现金选项收取每月现金让您灵活运用，以支持持续的医疗费用和收入损失。在上一次癌症诊断日仅1年的等待期后，若受保人仍然患有癌症，并正接受积极治疗，又或被确认为末期癌症，您可以选择每月收取5%原有保额，长达100个月或直至受保人年届85岁，以较先者为准。就严重疾病赔偿、持续癌症现金选项及特选危疾七重赔偿下的所有已付及须付的合资格癌症索偿，赔偿总额合计最高为600%原有保额。您需要每6个月提交由注册专科医生发出的报告："
              ),
            ]),
            _c("div", [_vm._v("i.罹患癌症；及")]),
            _c("div", [
              _vm._v(
                "ii.受保人正接受积极治疗，如已提供由注册专科医生发出的末期癌症证明除外。"
              ),
            ]),
            _c("div", [
              _vm._v(
                " 一旦已支付持续癌症现金选项，特定危疾七重赔偿则不会再为癌症提供赔偿。"
              ),
            ]),
            _c("div", [
              _vm._v(
                " 持续癌症现金选项保障至受保人85岁，并需符合15天生存期要求。"
              ),
            ]),
            _c("h3", [_vm._v("7.脑退化疾病终身年金赔偿：")]),
            _c("div", [
              _vm._v(
                "当严重疾病赔偿或特选危疾七重赔偿已就亚尔兹默氏病／不可还原之器质性脑退化疾病作赔偿，如受保人于85岁或以前仍然罹患亚尔兹默氏病／不可还原之器质性脑退化疾病并在30分为满分的简短智能测试（MMSE）中取得10分或以下及其1年等候期后，每年可获支付相等于6%原有保额的现金收入，直至保单终止，惟我们必须在每年保障赔偿支付日期前不少于1个月但不多于2个月前收到受保人在生的证明。"
              ),
            ]),
            _c("h3", [_vm._v(" 8.保障无间断：")]),
            _c("div", [
              _vm._v(
                "一旦受保疾病赔偿达至原有保额之100%，您将毋须再缴付基本保单的保费，只需继续缴交附加契约（如有）的保费，该附加契约将会继续生效，为您继续提供保障。"
              ),
            ]),
            _c("h3", [_vm._v(" 9.预期退保发还总额摘要：")]),
            _c("div", [_vm._v("此计划亦助您累积财富。")]),
            _vm._m(1),
            _c("div", [
              _vm._v(
                "预期退保发还总额相等于保证现金价值及任何非保证终期分红的总和。其他保单年度的预期退保发还总额可参考此建议书的分红保单销售说明文件（包括第6部份的说明）。"
              ),
            ]),
            _c("h3", [
              _vm._v(
                "10.透过您选择之年期内缴付的定额供款，便可享终身保障，助您灵活分配资金。保费金额并不会按年龄增加而递增，让您理财更有预算。此基本保单之保费并非保证不变，我们保留不时检讨及调整保费之权利。"
              ),
            ]),
            _c("h3", [_vm._v("11.免付保费附加契约（基本计划）：")]),
            _c("div", [
              _vm._v(
                "若受保人于60岁前，因受伤或疾病而被视为永久及完全丧失双眼视力，或丧失一肢及一眼视力，或丧失双肢，我们将会豁免相等于此附加契约保障額的该部分基本保单保障額之应付保费。"
              ),
            ]),
            _c("div", [
              _vm._v(
                " -免付保费附加契约（基本计划）之权益只给予一般标准受保人，并且本公司保留最终批核权。"
              ),
            ]),
            _c("div", [
              _vm._v(
                " -根据本公司当时的规则及规例，受保人于此附加契约、于基本保单或其他保单附加之任何免付保费附加契约（基本计划）及特级预支保额及免付保费附加契约（基本计划）合共之个人总保额不可超过美元300,000／港元2,250,000。"
              ),
            ]),
            _c("div", [
              _vm._v(" -本附加契约并不承保因自致的伤害而引起的残废。"),
            ]),
            _c("h3", [
              _vm._v(
                "12.此计划提供保单贷款，而息率由友邦不时决定。在支付任何利益前，我们将先扣除保单内所有未偿还的欠款。"
              ),
            ]),
            _c("div", [
              _vm._v(
                " 注：「我们」、「我们的」、「本公司」或「友邦」是指友邦保险（国际）有限公司（于百慕大注册成立之有限公司）。"
              ),
            ]),
          ])
        : _vm._e(),
      _vm.name == "危疾家康保"
        ? _c("div", { staticClass: "box-items" }, [
            _vm._m(2),
            _vm._m(3),
            _c("div", [_vm._v("请留意：")]),
            _c("div", [
              _vm._v(
                "i.除在受保疾病列表特别指明以外，对于危疾、早期危疾和儿童疾病而言："
              ),
            ]),
            _c("div", [
              _vm._v(
                " （i）首次出现病症或症状必须在此基本计划的保单签发日、生效日或最后复保日（以最迟者为准）起计最少90日（直接因意外而引致或由其而引起除外）；"
              ),
            ]),
            _c("div", [_vm._v("及")]),
            _c("div", [
              _vm._v(" （ii）受保人在被诊断证实罹患该类疾病后生存最少14日。"),
            ]),
            _c("div", [
              _vm._v(
                "ii.对于复杂手术2而言，首次发生时必须在此基本计划的保单签发日、生效日或最后复保日（以最迟者为准）起计最少90日（直接因意外而引致或由其而引起除外）。"
              ),
            ]),
            _c("div", [
              _vm._v(
                "iii.我们将不会就直接或间接因已存在的情况所致的或由其而引起的索偿支付任何赔偿。"
              ),
            ]),
            _c("div", [
              _vm._v(
                "有关词语之定义及保障之准确及完整的条款及条件，请参阅包括但不限于一般保障条款、基本保障条款及任何批文的相关保单文件样本。我们将应您要求提供相关文件。"
              ),
            ]),
          ])
        : _vm._e(),
      _vm.name == "活耀人生危疾保2"
        ? _c("div", { staticClass: "box-items" }, [
            _c("div", [_vm._v(" 产品保障一览")]),
            _c("h3", [_vm._v("a.危疾赔偿")]),
            _c("div", [
              _vm._v(
                "·包括严重危疾赔偿、早期危疾赔偿、儿童疾病危疾赔偿及深切治疗保障。"
              ),
            ]),
            _c("div", [
              _vm._v(
                "·涵盖60种严重危疾、44种早期危疾、8种儿童疾病及合资格的深切治疗部留医3。详情请参阅下列的备注及赔偿表。"
              ),
            ]),
            _c("div", [
              _vm._v(
                "·若受保人不幸于首10个保单年度内患上严重危疾，可获相等于基本计划名义金额之50%之额外赔偿。"
              ),
            ]),
            _c("div", [
              _vm._v(
                "·受限于其他条款及细则，若受保人需于同一间医院内入住深切治疗部连续3天或以上，本计划将提供基本计划名义金额之20%作深切治疗保障。"
              ),
            ]),
            _c("h3", [_vm._v("b.持续守护保障")]),
            _c("div", [
              _vm._v(
                " ·若已作出严重危疾赔偿，本计划将提供额外2次突发性心脏病或中风赔偿及额外2次癌症赔偿。详情请参阅下列的备注及赔偿表。"
              ),
            ]),
            _c("div", [
              _vm._v(
                " ·癌症的持续守护保障不单包括新诊断患上的癌症，更包括复发、扩散及持续之癌症。"
              ),
            ]),
            _c("h3", [_vm._v(" c.生活守护保障")]),
            _c("div", [
              _vm._v(
                " ·受限于相关条件及限制，若受保人无法进行六项「日常生活之活动」5中任何两项，并且在整个活动过程中需要另一个人从旁协助（「该伤残」），且不间断地持续180天或以上（由严重危疾赔偿下获赔偿之严重危疾的诊断日期后至少180日起计），本计划将额外提供基本计划名义金额之100%作生活守护保障。详情请参阅下列的备注及赔偿表。"
              ),
            ]),
            _c("h3", [_vm._v(" d.未获发现之先天性情况均得到保障")]),
            _c("div", [
              _vm._v(
                " ·为在缓接期内或以前仍未获发现之先天性情况（包括有关病征或征状）所导致的危疾提供保障。"
              ),
            ]),
            _c("h3", [_vm._v(" e.其他服务")]),
            _c("div", [
              _vm._v(
                " ·本计划可提供转介服务，受保人可就此寻求美国医院所提供之第二医疗意见。"
              ),
            ]),
            _c("h3", [_vm._v(" f.人寿保障及储蓄")]),
            _c("div", [
              _vm._v(
                " ·本计划会就受保人身故提供保障。详情请参阅下列的备注及赔偿表。"
              ),
            ]),
            _c("div", [
              _vm._v(
                " ·提供保证现金价值、期满利益及非保证终期红利。您可于终期红利锁定周年日锁定高达50%的终期红利，已锁定的终期红利可积存生息或随时提取。"
              ),
            ]),
            _c("h3", [_vm._v(" g.保证保费")]),
            _c("div", [_vm._v(" ·保费保证不变及不会于保费缴付期内增加。")]),
            _c("h3", [_vm._v(" h.全方位保障")]),
            _c("div", [
              _vm._v(
                " ·提供灵活及多元化的附加保障（如适用），根据自己需要选择额外人寿、医疗、危疾及意外保障。"
              ),
            ]),
            _c("h3", [_vm._v(" i.通胀加保权益")]),
            _c("div", [
              _vm._v(
                " ·危疾保障及身故赔偿每年按投保时基本计划名义金额递增5%，最多连续10个保单年度，以抵御通胀。"
              ),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        staticStyle: { width: "100%" },
        attrs: { src: require("@/assets/img/168.png") },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        staticStyle: { width: "100%" },
        attrs: { src: require("@/assets/img/abh9.png") },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        staticStyle: { width: "100%" },
        attrs: { src: require("@/assets/img/wkb1.png") },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        staticStyle: { width: "100%" },
        attrs: { src: require("@/assets/img/wkb2.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }